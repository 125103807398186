import { types } from 'mobx-state-tree'

const MeasurementUnitModel = types.model('MeasurementUnitModel', {
  id: types.number,
})

const CoefficientOverrideModel = types.model('CoefficientOverrideModel', {
  id: types.number,
  coefficient: types.number,
  physicalRegionId: types.number,
})

const ConvertCoefficientModel = types.model('ConvertCoefficientModel', {
  id: types.number,
  coefficient: types.number,
  fromMeasurementUnitId: types.number,
  toMeasurementUnitId: types.number,
  coefficientOverrides: types.array(CoefficientOverrideModel),
})

export const MaterialMeasurementUnitsModel = types.model('MaterialMeasurementUnitsModel', {
  id: types.number,
  name: types.string,
  defaultMeasurementUnit: types.maybe(MeasurementUnitModel),
  estimateMeasurementUnit: types.maybe(MeasurementUnitModel),
  importMeasurementUnits: types.array(MeasurementUnitModel),
  convertCoefficients: types.array(ConvertCoefficientModel),
})
