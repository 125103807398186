import { types } from 'mobx-state-tree'
import { DictionaryModel } from '../common'
import { MaterialPricesCoefficientsModel } from './MaterialPricesCoefficientsModel'
import { PriceRegionMeasurementUnitModel } from './PriceRegionMeasurementUnitModel'

export const MaterialPriceModel = types.model('MaterialPriceModel', {
  id: types.number,
  currencyId: types.number,
  measurementUnitId: types.number,
  headRegionMeasurementUnitId: types.number,
  materialId: types.number,
  isDeletable: types.boolean,
  lastModified: types.string,
  price: types.number,
  material: DictionaryModel,
  coefficients: types.array(MaterialPricesCoefficientsModel),
  priceRegionMeasurementUnits: types.array(PriceRegionMeasurementUnitModel),

  // countryId: types.number,
  // discountPercent: types.number,
})
