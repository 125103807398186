import { types } from 'mobx-state-tree'
import { DictionaryModel } from '../common'

export const PhysicalRegionModel = types.model('PhysicalRegionModel', {
  id: types.number,
  physicalRegionCode: types.number,
  name: types.string,
  countryId: types.number,
  priceRegions: types.array(DictionaryModel),
})
